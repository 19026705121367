//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;
$primary-dark: #4c3d3d;
$primary: #5d5050;
$primary-light: #5c5c5c;
$secondary: #f7be00;
$secondary-light: #fff7d4;

